import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/Link"
import { FluidImage, BackgroundFluidImage } from "@components/Image"
import * as css from "@css/components/blocks/NewsList.module.styl"

export default function PageNews({data, moreLink})
{
    const q = useStaticQuery(graphql`
        query {
            main01 : file(relativePath: { eq: "indexContent__img__main01@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            main02 : file(relativePath: { eq: "indexContent__img__main02@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            main03 : file(relativePath: { eq: "indexContent__img__main03@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    console.log(data)
    return(
        <section className={css.container}>
            <div className={css.inner}>
                <div className={css.list}>
                    { data && data.map(( {node}, i)=>{
                        const { date, title, uri} = node
                        return(
                            <Link className={css.item} key={i} to={uri}>
                                <span className={css.date}>{date}</span>
                                <span className={css.title}>{title}</span>
                            </Link>
                        )
                    })}
                    {moreLink &&
                        <div className={css.links}>
                            <Link className={css.button} to={moreLink}>
                                MORE
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}
