// extracted by mini-css-extract-plugin
export var animationMarquee = "f_c";
export var button = "f_p";
export var container = "f_b";
export var date = "f_m";
export var inner = "f_h";
export var item = "f_g";
export var label = "f_j";
export var links = "f_n";
export var list = "f_l";
export var title = "f_k";