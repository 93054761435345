import React from "react"
import { graphql , useStaticQuery} from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStores } from '@hooks/useStores'
import Seo from "@components/SEO"
import NewsList from "@components/blocks/NewsList"
import { Section, Title, Paragraph, Column, SmallTitle } from "@components/blocks/page/Section"
// import { Table } from "@components/blocks/page/Table"
// import { Tags } from "@components/blocks/page/Tags"

import * as css from "@css/components/blocks/page/Content.module.styl"

export default function Index()
{
	const q = useStaticQuery(graphql`
        query {
			b : file(relativePath: { eq: "IndexInformation__lbl__title.svg" }) { publicURL }
            newsData: allWpPost(
                limit: 9999
                sort: { order: DESC, fields: date }
            ) {
				edges {
					node {
                        title
						date(formatString: "YYYY.MM.DD")
						databaseId
						uri
					}
				}
			}
        }
    `)
	return(
		<>
		<Seo/>
		<div className={css.container}>
			<div className={css.inner}>
				<Section>
					<Title title1='INFORMATION' title2='' />
					<NewsList data={q.newsData.edges} />
					
				</Section>
			</div>
		</div>
		</>
	)
}